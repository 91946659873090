import { Box, CssBaseline, ThemeProvider, Toolbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import React, { useCallback, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Nullable } from "sonobello.utilities.react";
import { UserEventContextProvider } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEventContext";

import AppErrorBoundary from "./AppErrorBoundary";
import Theme from "./assets/Theme";
import Header from "./components/Header.v12";
import MainBackgroundContainer from "./components/main/Main.BackgroundContainer";
import DisqualifyingReasons from "./components/main/Main.DisqualifyingReasons";
import RecordCustomerNote from "./components/main/Main.RecordCustomerNote";
import ReportLeadDevice from "./components/main/Main.ReportLeadDevice";
import MainResizingContainer from "./components/main/Main.ResizingContainer";
import MedicalStep from "./components/steps/medical/MedicalStep";
import QualifyStep from "./components/steps/qualify/QualifyStep.v12";
import VersionWatermark from "./components/VersionWatermark";
import EndedSessionView, { EndedSessionViewProps } from "./components/views/EndedSessionView";
import ExpiredReservationView, { IExpiredReservationViewProps } from "./components/views/ExpiredReservationView";
import ForbiddenSessionView, { ForbiddenSessionViewProps } from "./components/views/ForbiddenSessionView";
import InvalidLinkView from "./components/views/InvalidLinkView";
import NoAvailabilityView, { NoAvailabilityViewProps } from "./components/views/NoAvailabilityView";
import NonCandidateView from "./components/views/NonCandidateView";
import SomethingWentWrongView from "./components/views/SomethingWentWrongView";
import ThankYouView, { IThankYouViewProps } from "./components/views/thankYou/ThankYouView";
import { Step, StepPathMap } from "./types/Step";
import { CachedViewProps, ViewConnector } from "./types/ViewConnector";
import { ViewKeyMap } from "./types/Views";
import { clearLocalStorage } from "./utils/LocalStorage";
import GlobalStylesViewController from "./V2/App/Components/GlobalStylesViewController";
import { HideUserWayStyle } from "./V2/App/Components/HideUserWayStyle";
import AppContextAuthorizationRenewer from "./V2/App/Compositions/AppContextAuthorizationRenewer";
import AppContextReportStepProgress from "./V2/App/Compositions/AppContextReportStepProgress";
import AppContextStepProvider from "./V2/App/Compositions/AppContextStepProvider.v12";
import AppContextUseLeadCustomerQualification from "./V2/App/Compositions/AppContextUseLeadCustomerQualification";
import OpsSupportedCalendarManager from "./V2/Calendar/Compositions/OpsCalendarManager";
import DisabledExperimentContextLoader from "./V2/Experiment/Compositions/DisabledExperimentContextLoader";
import ExperimentContextAppContextProvider from "./V2/Experiment/Compositions/ExperimentContextAppContextProvider";
import SessionResolver from "./V2/Routing/Components/SessionResolver";
import AppContextAppRootRedirect from "./V2/Routing/Composition/AppContextAppRootRedirect";
import AppContextRedirectToFirstStep from "./V2/Routing/Composition/AppContextRedirectToFirstStep";
import ObxRoutes from "./V2/Routing/Types/AppPaths";
import AppContextCenterSelectStep from "./V2/Steps/CenterSelect/Compositions/AppContextCenterSelectStep";
import AppContextCenterSelectStepWrapper from "./V2/Steps/CenterSelect/Compositions/AppContextCenterSelectStepWrapper";
import CenterServiceModalSelector from "./V2/Steps/CenterSelect/Compositions/CenterServiceModalSelector";
import UserEventCenterSelector from "./V2/Steps/CenterSelect/Compositions/UserEventCenterSelector";
import { IStepWrapperButtonProps } from "./V2/Steps/Components/StepWrapper";
import AppContextReservationCounter from "./V2/Steps/Compositions/AppContextReservationCounter";
import UserEventNextButtonDesktop from "./V2/Steps/Compositions/UserEventNextButtonDesktop";
import UserEventNextButtonMobile from "./V2/Steps/Compositions/UserEventNextButtonMobile";
import AppContextConfirmationStep from "./V2/Steps/Confirmation/Compositions/AppContextConfirmationStep.v12";
import AppContextOpsConfirmationStepWrapper from "./V2/Steps/Confirmation/Compositions/AppContextOpsConfirmationStepWrapper";
import AppContextUseConfirmAppointment from "./V2/Steps/Confirmation/Compositions/AppContextUseConfirmAppointment";
import useConfirmLegacyAppointment from "./V2/Steps/Confirmation/Hooks/UseConfirmLegacyAppointment";
import useConfirmOpsAppointmentWithDistance from "./V2/Steps/Confirmation/Hooks/UseConfirmOpsAppointmentWithDistance";
import useCreateLeadResult from "./V2/Steps/Confirmation/Hooks/UseCreateLeadResult";
import useGetCalendarEventUrl from "./V2/Steps/Confirmation/Hooks/UseGetCalendarEventUrl";
import AppContextSchedulingLoader from "./V2/Steps/Scheduling/Compositions/AppContextSchedulingLoader";
import HybridCalendarSchedulingStep from "./V2/Steps/Scheduling/Compositions/AppContextSchedulingStepWrapper";
import AppContextWelcomeStep from "./V2/Steps/Welcome/Compositions/AppContextWelcomeStep";
import ISession from "./V2/Types/ISession";
import ObxLoadSessionView from "./V2/Views/LoadSession/Compositions/ObxLoadSessionView";

/** A collection of the flows supported by this application. */

const defaultNextButtonConfiguration: IStepWrapperButtonProps = {
  NextButtonDesktop: UserEventNextButtonDesktop,
  NextButtonMobile: UserEventNextButtonMobile
};

const App: React.FC = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const [sessionState, setSessionState] = useState<Nullable<ISession>>(null);
  const [viewPropsState, setViewPropsState] = useState<unknown>(CachedViewProps.loadFromCache());
  const clearSession = useCallback(() => {
    setSessionState(null);
    clearLocalStorage();
  }, []);
  const viewConnector = useRef(
    new ViewConnector(clearSession, navigate, sessionKey => (location.href = `/${sessionKey}`), setViewPropsState)
  );
  const startSession = useCallback((session: ISession) => {
    setSessionState(session);
    navigate(ObxRoutes.stepPrefix);
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStylesViewController mobileStyles={[HideUserWayStyle]} desktopStyles={[]} />
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"en-US"}>
        <CssBaseline />
        <UserEventContextProvider>
          <Box height="100%" width="100%" display="flex" flexDirection="column">
            <Header pathName={routerLocation.pathname} />
            <Toolbar />
            <AppErrorBoundary goToSomethingWentWrongView={viewConnector.current.goToSomethingWentWrongView}>
              <Box component="main" display="flex" flexGrow={1}>
                <MainBackgroundContainer>
                  <MainResizingContainer>
                    <SessionResolver
                      pathName={routerLocation.pathname}
                      router={viewConnector.current}
                      value={sessionState}
                      onChange={setSessionState}
                    >
                      <Routes>
                        <Route
                          path={ObxRoutes.stepPrefix}
                          element={
                            <DisabledExperimentContextLoader
                              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                              experimentIdentifier={sessionState?.customer.id!}
                              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                              bearerAuthToken={sessionState?.token.token!}
                            >
                              <ExperimentContextAppContextProvider
                                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                                bearerAuthToken={sessionState?.token.token!}
                                session={sessionState!}
                                pathName={routerLocation.pathname}
                                viewConnector={viewConnector.current}
                              >
                                <AppContextAppRootRedirect pathName={routerLocation.pathname}>
                                  <AppContextStepProvider
                                    pathName={routerLocation.pathname}
                                    router={viewConnector.current}
                                  />
                                  <DisqualifyingReasons viewConnector={viewConnector.current} />
                                  <RecordCustomerNote />
                                  <ReportLeadDevice />
                                  <OpsSupportedCalendarManager
                                    router={viewConnector.current}
                                    pathName={routerLocation.pathname}
                                  />
                                  <AppContextReportStepProgress pathName={routerLocation.pathname} />
                                  <AppContextAuthorizationRenewer router={viewConnector.current} />
                                  <AppContextUseLeadCustomerQualification />
                                </AppContextAppRootRedirect>
                              </ExperimentContextAppContextProvider>
                            </DisabledExperimentContextLoader>
                          }
                        >
                          <Route path={StepPathMap[Step.welcome]} element={<AppContextWelcomeStep />} />
                          <Route
                            path={StepPathMap[Step.qualify]}
                            element={<QualifyStep viewConnector={viewConnector.current} />}
                          />
                          <Route
                            path={StepPathMap[Step.medicalPartOne]}
                            element={<MedicalStep viewConnector={viewConnector.current} />}
                          />
                          <Route
                            path={StepPathMap[Step.medicalPartTwo]}
                            element={<MedicalStep viewConnector={viewConnector.current} />}
                          />
                          <Route
                            path={StepPathMap[Step.medicalA1C]}
                            element={<MedicalStep viewConnector={viewConnector.current} />}
                          />
                          <Route
                            path={StepPathMap[Step.centerSelection]}
                            element={
                              <AppContextSchedulingLoader>
                                <AppContextCenterSelectStepWrapper
                                  {...defaultNextButtonConfiguration}
                                  CenterSelectStep={props => (
                                    <AppContextCenterSelectStep {...props} CenterSelector={UserEventCenterSelector} />
                                  )}
                                  ServiceSelector={CenterServiceModalSelector}
                                />
                              </AppContextSchedulingLoader>
                            }
                          />
                          <Route
                            path={StepPathMap[Step.scheduling]}
                            element={
                              <AppContextSchedulingLoader>
                                <HybridCalendarSchedulingStep {...defaultNextButtonConfiguration} />
                              </AppContextSchedulingLoader>
                            }
                          />
                          <Route
                            path={StepPathMap[Step.confirmation]}
                            element={
                              <AppContextOpsConfirmationStepWrapper
                                {...defaultNextButtonConfiguration}
                                router={viewConnector.current}
                                useConfirmAppointment={() =>
                                  AppContextUseConfirmAppointment({
                                    useCreateLegacyAppointment: useConfirmLegacyAppointment,
                                    useCreateOpsAppointment: useConfirmOpsAppointmentWithDistance,
                                    useCreateLeadResult: useCreateLeadResult
                                  })
                                }
                                useGetCalendarEventUrl={useGetCalendarEventUrl}
                              >
                                <AppContextConfirmationStep>
                                  <AppContextReservationCounter />
                                </AppContextConfirmationStep>
                              </AppContextOpsConfirmationStepWrapper>
                            }
                          />
                          <Route path="*" element={<AppContextRedirectToFirstStep />} />
                        </Route>
                        <Route
                          path={ViewKeyMap.thankYou.uri}
                          element={<ThankYouView {...(viewPropsState as IThankYouViewProps)} />}
                        />
                        <Route
                          path={ViewKeyMap.completed.uri}
                          element={<ForbiddenSessionView {...(viewPropsState as ForbiddenSessionViewProps)} />}
                        />
                        <Route
                          path={ViewKeyMap.endedSession.uri}
                          element={<EndedSessionView {...(viewPropsState as EndedSessionViewProps)} />}
                        />
                        <Route
                          path={ViewKeyMap.noAvailability.uri}
                          element={<NoAvailabilityView {...(viewPropsState as NoAvailabilityViewProps)} />}
                        />
                        <Route path={ViewKeyMap.nonCandidate.uri} element={<NonCandidateView />} />
                        <Route
                          path={ViewKeyMap.expiredReservation.uri}
                          element={<ExpiredReservationView {...(viewPropsState as IExpiredReservationViewProps)} />}
                        />
                        <Route path={ViewKeyMap.invalidLink.uri} element={<InvalidLinkView />} />
                        <Route path={ViewKeyMap.somethingWentWrong.uri} element={<SomethingWentWrongView />} />
                        <Route
                          path="*"
                          element={
                            <ObxLoadSessionView
                              onChange={startSession}
                              router={viewConnector.current}
                              sessionKey={location.pathname.substring(1)}
                            />
                          }
                        />
                      </Routes>
                    </SessionResolver>
                  </MainResizingContainer>
                </MainBackgroundContainer>
              </Box>
            </AppErrorBoundary>
          </Box>
          <VersionWatermark />
        </UserEventContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
