import { useRef } from "react";

import ExperimentContextLoader, {
  ExperimentContextHook,
  IExperimentContextLoaderConfig,
  IExperimentContextLoaderProps
} from "../Components/ExperimentContextLoader";
import useReportConnectionError from "../Hooks/UseReportConnectionError";
import { DefaultExperimentContextProps } from "../Types/IExperimentContextProps";

const useDisableExperimentation: ExperimentContextHook = () => {
  const valueRef = useRef<ReturnType<ExperimentContextHook>>({
    result: new DefaultExperimentContextProps(),
    error: null,
    isLoading: false
  });
  return valueRef.current;
};

const useNoActiveExperiments: IExperimentContextLoaderConfig["useGetActiveExperiments"] = () => {
  return { result: [], error: null, isLoading: false };
};

/** A {@link ExperimentContextLoader} which supplies an experiment context that disables all experimentation. */
const DisabledExperimentContextLoader: React.FC<IExperimentContextLoaderProps> = props =>
  ExperimentContextLoader({
    ...props,
    useGetActiveExperiments: useNoActiveExperiments,
    useFirstUseGetExperimentContext: useDisableExperimentation,
    useReportConnectionError: useReportConnectionError
  });

export default DisabledExperimentContextLoader;
